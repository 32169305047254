import OperationPlans from "./menu-operationplans.js";
import OperationPlan from "./menu-operationplan.js";
import Notifications from "./menu-notifications.js";
import Privacy from "./menu-privacy.js";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { getItem, setItem } from "./storage.js";
import { post } from "./request.js";

const platform = Capacitor.getPlatform();

const { ENV, alert, confirm, redom, matchMedia, location } = window;
const { el, list, router } = redom;

const clientVersion = ENV.VERSION;

export default class Menu {
  constructor({ app, api, i18n }) {
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      "#menu",
      (this.version = el(".version")),
      el(
        ".menu-wrapper",
        (this.operator = el(".operator")),
        (this.items = list(".menu-items", MenuItemGroup, null, api)),
        (this.content = router(
          ".content",
          {
            notifications: Notifications,
            operationplans: OperationPlans,
            operationplan: OperationPlan,
            privacy: Privacy,
          },
          { api, app, i18n }
        )),
        el(".small-menu-items", (this.removeAccount = el(".small-menu-item"))),
        (this.removeAccountForm = el(
          ".remove-account-form",
          { style: { display: "none" } },
          (this.removeAccountDisclaimer = el("p")),
          (this.removeAccountCancel = el("button.button.grey", {
            style: { marginRight: ".25rem", marginBottom: ".25rem" },
          })),
          (this.removeAccountSend = el("button.button.red"))
        ))
      ),
      (this.chooseLanguage = list("select.choose-language", Option)),
      (this.close = new Close(api))
    );

    this.el.onclick =
      this.el.onmousedown =
      this.el.ontouchstart =
      (e) => {
        e.menuclick = true;
      };

    this.chooseLanguage.el.onchange = async () => {
      i18n.LANG = this.chooseLanguage.el.value;
      api.update();
      try {
        await setItem("lang", i18n.LANG);
      } catch (err) {
        console.error(err);
      }
    };
  }

  onmount() {
    const from = matchMedia("(min-width: 640px)").matches
      ? "translate3d(-100%, 0, 0)"
      : "translate3d(0, 100%, 0)";
    this.el.animate(
      [{ transform: from }, { transform: "translate3d(0, 0, 0)" }],
      {
        duration: 250,
        iterations: 1,
        fill: "both",
        timingFunction: "cubic-bezier(0.33, 1, 0.68, 1)",
      }
    );
  }

  hide() {
    this.hide = () => { };
    const to = matchMedia("(min-width: 640px)").matches
      ? "translate3d(-100%, 0, 0)"
      : "translate3d(0, 100%, 0)";
    this.el.animate(
      [{ transform: "translate3d(0, 0, 0)" }, { transform: to }],
      {
        duration: 250,
        iterations: 1,
        fill: "both",
        timingFunction: "cubic-bezier(0.33, 1, 0.68, 1)",
      }
    ).onfinish = () => {
      this.api.menuClosing = false;
      this.api.update();
    };
  }

  onunmount() {
    this.el.removeEventListener("scroll", this, { passive: true });
  }

  handleEvent(e) {
    if (e.type === "scroll") {
      if (this.api.menuSection || this.el.scrollTop > 0) {
        this.chooseLanguage.el.style.display = "none";
      } else {
        this.chooseLanguage.el.style.display = "";
      }
    }
  }

  update(api) {
    const { i18n } = this;
    const { APP_ID, HOST, LANGUAGES, VERSION, ACCOUNT_REMOVAL } = window.ENV;
    const { user, menuClosing } = api;

    if (menuClosing) {
      this.hide();
    }

    if (user && ACCOUNT_REMOVAL) {
      this.removeAccount.parentNode.style.display = "";
      this.removeAccount.textContent = i18n("removeAccount");

      this.removeAccountDisclaimer.innerHTML = i18n("removeAccount.disclaimer");
      this.removeAccountSend.textContent = i18n("removeAccount");
      this.removeAccountCancel.textContent = i18n("removeAccount.cancel");

      this.removeAccountCancel.onclick = () => {
        this.removeAccountForm.style.display = "none";
      };

      this.removeAccount.onclick = () => {
        this.removeAccountForm.style.display = "";
      };

      this.removeAccountSend.onclick = async () => {
        if (confirm(i18n("removeAccount.confirm"))) {
          try {
            const { ok } = JSON.parse(
              await post(`${HOST}auth/requestaccountremove`)
            );

            if (!ok) {
              throw new Error("Something went wrong");
            }

            alert(i18n("removeAccount.requested"));
            const id = await getItem("id");
            let url = `${HOST}auth/skyzr/logout`;

            if (APP_ID) {
              url += `?app=${APP_ID}`;
              url += `&id=${id}`;
            } else {
              url += `?id=${id}`;
            }

            openLink(url);
          } catch (err) {
            console.error(err);
            alert("Something went wrong");
          }
        }
      };
    } else {
      this.removeAccount.parentNode.style.display = "none";
    }

    this.version.textContent = VERSION ? `v${VERSION}` : "";

    if (clientVersion !== VERSION) {
      this.version.textContent = `v${clientVersion} / ${this.version.textContent}`;
    }

    this.operator.textContent = "";
    this.operator.style.display = "";

    if (user) {
      if (user.company && user.company.name) {
        const companyLink = document.createElement("a");
        companyLink.href = "#";
        companyLink.textContent = user.company.name;
        companyLink.onclick = (e) => {
          e.preventDefault();
          const url = `${HOST}auth/skyzr/company${APP_ID ? `?app=${APP_ID}` : ""
            }`;

          openLink(url, true);
        };
        this.operator.appendChild(
          document.createTextNode(`${i18n("operator")}: `)
        );
        this.operator.appendChild(companyLink);
      } else {
        this.operator.style.display = "none";
      }
    } else {
      const signInLink = document.createElement("a");
      signInLink.textContent = `${i18n("auth.signIn")} / ${i18n(
        "auth.signUp"
      )}`;
      signInLink.href = "#";
      signInLink.onclick = (e) => {
        e.preventDefault();
        const url = `${HOST}auth/skyzr/login${APP_ID ? `?app=${APP_ID}` : ""}`;

        openLink(url);
      };
      this.operator.appendChild(signInLink);
    }

    this.chooseLanguage.update(
      LANGUAGES.split(",")
        .map((str) => str.trim())
        .map((value) => {
          return { value, text: i18n(`lang.${value}`) };
        })
    );
    this.chooseLanguage.el.value = i18n.LANG;
    this.items.update(
      api.menuSection
        ? []
        : [
          [
            { name: i18n("sections.operationplans"), id: "operationplans" },
            { name: i18n("sections.notifications"), id: "notifications" },
          ],
          [
            {
              name: i18n("sections.profile"),
              onclick: () => {
                openLink(
                  `${HOST}auth/skyzr/profile${APP_ID ? `?app=${APP_ID}` : ""
                  }`,
                  true
                );
              },
            },
            {
              name: i18n("sections.report"),
              onclick: () => {
                openLink(i18n("urls.reportOccurence"), true);
              },
            },
            {
              name: i18n("sections.info"),
              id: "info",
              onclick: () => {
                openLink(i18n("urls.additionalInfo"), true);
              },
            },
          ],
          [
            {
              name: i18n("sections.dataprotection"),
              onclick: () => {
                openLink(i18n("urls.dataProtection"), true);
              },
            },
            {
              name: i18n("sections.terms"),
              onclick: () => {
                openLink(i18n("urls.terms"), true);
              },
            },
            {
              name: i18n("sections.impressum"),
              onclick: () => {
                openLink(i18n("urls.impressum"), true);
              },
            },
            /*,
          { name: 'Help', id: 'help' } */
          ],
        ]
    );
    if (api.menuSection) {
      this.content.el.style.display = "";
      this.items.el.style.display = "none";
      this.chooseLanguage.el.style.display = "none";
      this.removeAccount.style.display = "none";
    } else {
      this.content.el.style.display = "none";
      this.items.el.style.display = "";
      this.chooseLanguage.el.style.display = "";
      this.removeAccount.style.display = "";
    }
    this.content.update(api.menuSection, api);
    this.close.update(api);
  }
}

class MenuItemGroup {
  constructor(api) {
    this.el = list(".menu-item-group", MenuItem, null, api);
  }

  update(data) {
    this.el.update(data);
  }
}

class MenuItem {
  constructor(api) {
    this.api = api;
    this.el = el(
      ".menu-item",
      (this.name = el("span")),
      (this.icon = el("i.icon"))
    );
  }

  update(data) {
    this.name.textContent = data.name;

    if (data.onclick) {
      this.icon.className = "icon ti ti-circle-arrow-up-right-filled";
    } else {
      this.icon.className = "icon";
    }

    this.el.onclick = () => {
      if (data.onclick) {
        return data.onclick();
      }
      this.api.menuSection = data.id;
      this.api.update();
    };
  }
}

class Close {
  constructor(api) {
    this.api = api;
    this.el = el(".close", (this.icon = el("i.ti.ti-arrow-left")));

    this.el.onclick = () => {
      const { menuSection } = api;
      if (menuSection) {
        if (menuSection === "operationplan") {
          api.menuSection = "operationplans";
        } else {
          api.menuSection = null;
        }
      } else {
        api.menuSection = null;
        api.menuOpened = false;
        api.menuClosing = true;
      }
      api.update();
    };
  }

  update(data) {
    const { menuSection } = data;

    if (menuSection) {
      this.icon.className = "ti ti-arrow-left";
    } else {
      this.icon.className = "ti ti-x";
    }
  }
}

class Option {
  constructor() {
    this.el = el("option");
  }

  update(data) {
    const { value, text } = data;

    this.el.value = value;
    this.el.text = text;
  }
}

function openLink(url, newWindow) {
  if (platform !== "web") {
    Browser.open({ url });
  } else {
    if (newWindow) {
      window.open(url);
    } else {
      location.href = url;
    }
  }
}
