import { Preferences } from "@capacitor/preferences";

export async function request(url, options, callback) {
  const id = await getItem("id");

  return fetch(url, {
    ...options,
    headers: {
      ...((options || {}).headers || {}),
      "x-id": id || "",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res;
      }
      callback && callback(res.status, res.responseText);
      throw new Error(res.responseText || res.status);
    })
    .then((res) => {
      callback && callback();
      return res.text();
    });
}

export async function get(url, options, callback) {
  return await request(url, {
    ...(options || {}),
    method: "GET",
  }, callback);
}

export async function post(url, options, callback) {
  return await request(url, {
    ...(options || {}),
    method: "POST",
    headers: {
      ...((options || {}).headers || {}),
      "Content-Type": "application/json",
    },
  }, callback);
}

async function getItem(key) {
  return (await Preferences.get({ key })).value;
}
