const { redom } = window;
const { el } = redom;

export default class MenuButton {
  constructor({ app, api, i18n }) {
    this.api = api;

    this.el = el("#menu-button", el("i.ti.ti-menu"));

    this.el.onmousedown =
      this.el.ontouchstart =
      this.el.onclick =
      (e) => {
        e.menuclick = true;
        api.menuOpened = true;
        api.menuSection = null;
        api.menuId = null;
        api.update();
      };
  }

  update(data) { }
}
