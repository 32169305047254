import { Preferences } from "@capacitor/preferences";
import DatetimeInput from "./datetime-input.js";

const { redom } = window;
const { el, svg } = redom;

export default class Time {
  constructor({ app, api, i18n }) {
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      ".time",
      (this.clock = el(
        ".clock",
        svg(
          "svg",
          { width: "1.5rem", height: "1.5rem", viewBox: "0 0 24 24" },
          (this.circle = svg("circle", { cx: 12, cy: 12, r: 10 })),
          (this.hour = svg("path.clock-hour", { d: "M12 7.5L12 13" })),
          (this.minute = svg("path.clock-minute", { d: "M12 5L12 13.5" })) /* ,
          this.second = svg('path.clock-second', { d: 'M12 4L12 14.5' }) */
        )
      )),
      (this.select = el(
        "div",
        { style: { display: "none" } },
        (this.timeTitle = el("p")),
        (this.time = new DatetimeInput({ i18n })),
        (this.bufferTitle = el("p")),
        (this.buffer = el(
          "select",
          [15, 30, 45, 60, 90, 2 * 60, 3 * 60, 4 * 60, 5 * 60, 6 * 60].map(
            (buffer) => {
              if (buffer > 90) {
                return el(
                  "option",
                  { value: buffer },
                  `${Math.round(buffer / 60)} h`
                );
              } else {
                return el(
                  "option",
                  { value: buffer, selected: buffer === 60 },
                  `${buffer} min`
                );
              }
            }
          )
        )),
        (this.now = el("button.button"))
      ))
    );
    api.browseBuffer = 60;
    this.el.onmousedown = this.el.ontouchstart = (e) => {
      e.clockClick = true;
      if (this.select.style.display === "none") {
        this.el.classList.add("opened");
        this.clock.style.display = "none";
        this.select.style.display = "";
        api.timeOpened = true;
        api.update();
      }
      window.addEventListener("mousedown", this);
      window.addEventListener("touchstart", this);
    };
    this.time.onfocus = () => {
      if (api.browseTime) {
        const time = new Date(this.time.value);
        time.setSeconds(0);
        this.time.value = time;
        this.time.update();
      } else {
        const time = new Date();
        time.setSeconds(0);
        this.time.value = time;
        this.time.update();
      }
    };

    this.buffer.onchange = () => {
      api.browseBuffer = this.buffer.value;
      if (api.browseTime) {
        this.time.oninput();
      }
    };

    this.time.oninput = async () => {
      console.log(this.time.value);
      api.browseTime = this.time.value && new Date(this.time.value);
      this.update();

      this.now.textContent = i18n("updatingMap");
      api.fetchUAS(true, true);

      for (const key in ["operationplans", "uas"]) {
        const source = this.app.map.map.getSource(key);
        if (source) {
          const id = await getItem("id");
          source._data = `${
            source._data.split(".geojson")[0]
          }.geojson?start=${this.api.browseTime.toISOString()}&end=${this.api.browseTime.toISOString()}&buffer=${
            this.buffer.value
          }`;
          if (id) {
            source._data += `&id=${id}`;
          }
          clearTimeout(this._updating);
          this._updating = setTimeout(() => {
            source.setData(source._data);
          }, 1000);
        }
      }
    };

    this.now.onclick = async () => {
      if (api.browseTime) {
        api.browseTime = null;
        this.time.value = null;
        this.now.textContent = i18n("updatingMap");
        api.fetchUAS();
        this.update();
        setTimeout(() => {
          this.tick();
        }, 100);

        const source = this.app.map.map.getSource("operationplans");
        if (source) {
          const id = await getItem("id");
          source._data = `${source._data.split(".geojson")[0]}.geojson`;
          if (id) {
            source._data += `?id=${id}`;
          }
          clearTimeout(this._updating);
          this._updating = setTimeout(() => {
            source.setData(source._data);
          }, 1000);
        }
      }
    };
  }

  handleEvent(e) {
    if (e.type === "mousedown" || e.type === "touchstart") {
      if (e.clockClick) {
        return;
      }
      if (!this.api.browseTime && this.select.style.display !== "none") {
        this.select.style.display = "none";
        this.clock.style.display = "";
        this.el.classList.remove("opened");
        this.api.timeOpened = false;
        this.api.update();
        window.removeEventListener("mousedown", this);
        window.removeEventListener("touchstart", this);
      }
    }
  }

  update(data) {
    const { api, i18n } = this;

    if (api.isFetchingUAS) {
      this.now.textContent = i18n("updatingMap");
    } else {
      if (api.browseTime) {
        this.now.textContent = i18n("showLiveView");
      } else {
        this.now.textContent = i18n("showingLiveView");
      }
    }

    this.timeTitle.textContent = i18n("timeSwitch");
    this.bufferTitle.textContent = i18n("timeBuffer");

    this.now.style.display = api.browseTime ? "" : "none";

    if (api.browseTime) {
      this.time.el.classList.add("set");
    } else {
      this.time.el.classList.remove("set");
    }

    this.time.update();
  }

  tick() {
    const { api, i18n } = this;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.tick();
    }, 1000 - (Date.now() % 1000));

    if (api.isFetchingUAS) {
      this.now.textContent = i18n("updatingMap");
    } else {
      if (api.browseTime) {
        this.now.textContent = i18n("showLiveView");
      } else {
        this.now.textContent = i18n("showingLiveView");
      }
    }

    if (this.clock.style.display === "none") {
      const hourRotate =
        (((new Date().getHours() + new Date().getMinutes() / 60) / 12) % 12) *
        360;
      const minuteRotate = (new Date().getMinutes() / 60) * 360;
      // const secondRotate = (new Date().getSeconds() / 60) * 360;

      this.hour.style.transform = `rotate(${hourRotate}deg)`;
      this.minute.style.transform = `rotate(${minuteRotate}deg)`;
      // this.second.style.transform = `rotate(${secondRotate}deg)`;
    } else {
      this.time.update();
    }
  }

  onmount() {
    this.tick();
  }

  onunmount() {
    clearTimeout(this.timeout);
  }
}

async function getItem(key) {
  return (await Preferences.get({ key })).value;
}
